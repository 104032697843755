import { CompanyRepoImpl } from "domain/repository/Company/CompanyRepoImpl";
import { MasterDataRepoImpl } from "domain/repository/MasterData/MasterDataRepoImpl";
import { StaffAndVesselCodeRepoImpl } from "domain/repository/StaffAndVesselCode/StaffAndVesselCodeRepoImpl";
import { UserRepoImpl } from "domain/repository/User/UserRepoImpl";
import { useStaffAndVesselCodeMaintenanceTracked } from "presentation/store/StaffAndVesselCode/StaffAndVesselCodeMaintenanceProvider";
import { StaffAndVesselCodeMaintenanceVM } from "presentation/viewModel/StaffAndVesselCode/StaffAndVesselCodeMaintenanceVM";
import { useMemo } from "react";
export const useStaffAndVesselCodeMaintenanceVM = () => { 
    const [, setStaffAndVesselCodeMaintenanceState] = useStaffAndVesselCodeMaintenanceTracked(); 
    const StaffAndVesselCodeMainVM = useMemo(() => 
        StaffAndVesselCodeMaintenanceVM({ 
            dispatch: [setStaffAndVesselCodeMaintenanceState], 
            repo: StaffAndVesselCodeRepoImpl(), 
            masterDataRepo: MasterDataRepoImpl(),
            companyRepo: CompanyRepoImpl(),
            userRepo: UserRepoImpl(),
        }), [setStaffAndVesselCodeMaintenanceState]) 
    return StaffAndVesselCodeMainVM 
} 

import { SelectionChangedEvent } from "ag-grid-community";
import { StaffAndVesselCodeEntity } from "domain/entity/StaffAndVesselCode/StaffAndVesselCodeEntity";
import { MessageConstant } from "presentation/constant/MessageConstant";
import { INITIAL_STAFF_VSL_CODE_COL_DEF } from "presentation/constant/StaffAndVesselCode/StaffAndVesselCodeMaintenanceColumnDefinition";
import { StaffAndVesselCodeMaintenanceConstant } from "presentation/constant/StaffAndVesselCode/StaffAndVesselCodeMaintenanceConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useStaffAndVesselCodeMaintenanceVM } from "presentation/hook/StaffAndVesselCode/useStaffAndVesselCodeMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useStaffAndVesselCodeMaintenanceTracked } from "presentation/store/StaffAndVesselCode/StaffAndVesselCodeMaintenanceProvider";
import { ConfirmDeleteModal } from "presentation/view/components/ConfirmDeleteModal";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useMemo, useRef, useState } from "react";
import { Loader } from "veronica-ui-component/dist/component/core";
const StaffAndVesselCodeTablePanel: React.FC = () => {
    const [staffAndVesselCodeState] = useStaffAndVesselCodeMaintenanceTracked();
    const staffAndVesselCodeVM = useStaffAndVesselCodeMaintenanceVM();
    const gridRef: any = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isShowDelete, setIsShowDelete] = useState<boolean>(false);
    const [deletedRow, setDeletedRow] = useState<StaffAndVesselCodeEntity[]>();
    const messageBarVM = useMessageBarVM();
    const { tableData, selectedRows } = staffAndVesselCodeState;
    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();
        staffAndVesselCodeVM.updateSelectedRows(tableData, selectedRows);
    }, [staffAndVesselCodeVM, tableData])
    const handleAddClick = useCallback(() => {
        staffAndVesselCodeVM.onAdd();
    }, [staffAndVesselCodeVM])
    const handleRowDoubleClick = useCallback((entity: StaffAndVesselCodeEntity) => {
        staffAndVesselCodeVM.onOpenEdit(entity);
    }, [staffAndVesselCodeVM])
    const memoBtns = useMemo(() => {

        const hdrBtns = [];
        hdrBtns.push({
            id: 'onDeleteButton',
            icon: 'Icon-trash',
            title: 'Delete'
        })

        return hdrBtns
    }, [])

    const handleDeleteClick = useCallback(async () => {
        if (selectedRows.length === 0) {
            messageBarVM.showError("Please select a record");
            return;
        }
        setIsShowDelete(true);
        setDeletedRow(selectedRows);
    }, [messageBarVM, selectedRows]);

    const handleCancel = () => {
        setIsShowDelete(false);
    }

    const handleDelete = async () => {
        setIsLoading(true);
        setIsShowDelete(false);
        if (!deletedRow) return;
        try {
            const res = await staffAndVesselCodeVM.onDeleteClick(selectedRows);
            if (!!!res || !res.success) {
                messageBarVM.showError(res?.data ?? 'Delete data failed.');
            } else {
                staffAndVesselCodeVM.onSearch().then((data) => {
                    setIsLoading(false)
                }).catch(error => {
                    setIsLoading(false)
                });
            }
        } catch (error) {

            setIsLoading(false)
        }
    }

    const memoTable = useMemo(() => {
        return (
            <NbisTable
                id='staff-vsl-code-table'
                headerLabel={StaffAndVesselCodeMaintenanceConstant.TITLE}
                isNewColumnSetting={true}
                columns={INITIAL_STAFF_VSL_CODE_COL_DEF}
                data={tableData ?? []}
                showPaginator={false}
                editable={false}
                headerActionButtons={memoBtns}
                onDeleteButton={handleDeleteClick}
                rowSelection={"multiple"}
                showAddIcon={true}
                onAddClick={handleAddClick}
                showDeleteButton={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                selectionMode={false}
                isRowHighligted={true}
                onSelectionChanged={handleSelectionChange}
                onRowDoubleClick={(e: any, entity: StaffAndVesselCodeEntity) => handleRowDoubleClick(entity)}
                gridHeight="customHeight"
                customHeight="calc(100vh - 90px)"
                ref={gridRef}
            />
        );
    }, [tableData, memoBtns, handleDeleteClick, handleAddClick, handleSelectionChange, handleRowDoubleClick])
    return <>
        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
        <TableWrapper>
            {memoTable}
        </TableWrapper>
        <ConfirmDeleteModal headerText={`${WorkspaceConstant.Common.BUTTON_DELETE} ${StaffAndVesselCodeMaintenanceConstant.TITLE}`}
            contentText={`${MessageConstant.common.DELETE_ALERT_TITLE}\n${MessageConstant.common.DELETE_ALERT_MESSAGE}`}
            visible={isShowDelete}
            onCancelClick={handleCancel}
            onDeleteClick={handleDelete}
        />
    </>;
}
export default memo(StaffAndVesselCodeTablePanel); 

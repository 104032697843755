import { StaffAndVesselCodeMaintenanceConstant } from "presentation/constant/StaffAndVesselCode/StaffAndVesselCodeMaintenanceConstant";
import { useStaffAndVesselCodeMaintenanceVM } from "presentation/hook/StaffAndVesselCode/useStaffAndVesselCodeMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useStaffAndVesselCodeMaintenanceTracked } from "presentation/store/StaffAndVesselCode/StaffAndVesselCodeMaintenanceProvider";
import { memo, useCallback, useState } from "react";
import { HPHBreadcrumb, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
const StaffAndVesselCodeEditPanelTitleBar: React.FC = () => {
    const [staffAndVesselCodeState] = useStaffAndVesselCodeMaintenanceTracked();
    const staffAndVesselCodeVM = useStaffAndVesselCodeMaintenanceVM();
    const messageBarVM = useMessageBarVM();
    const { currentSelectedRow, masterState } = staffAndVesselCodeState;
    const { editingEntity, isAdd, isEditable, isRead } = masterState;

    const [anainfoState] = useANAInfoTracked();
    const { allowCreate, allowUpdate } = anainfoState;
    const [isLoading, setIsLoading] = useState(false);
    const currentEntity = isRead ? currentSelectedRow : editingEntity;
    const handleReset = useCallback(async () => {
        staffAndVesselCodeVM.onReset();
    }, [staffAndVesselCodeVM]);
    const handleEdit = useCallback(() => {
        staffAndVesselCodeVM.onEdit(currentEntity);
    }, [currentEntity, staffAndVesselCodeVM])
    const handleSave = useCallback(async () => {
        setIsLoading(true);
        try {
            staffAndVesselCodeVM.onSaveClicked();
            const res = await staffAndVesselCodeVM.onSave(currentEntity, isAdd);
            if (!!!res || !res.success) {
                messageBarVM.showWarining(res?.data ?? 'Save data failed.');
            } else {
                staffAndVesselCodeVM.onSearch().then((data) => {
                    setIsLoading(false)
                }).catch(error => {
                    setIsLoading(false)
                });
            }
        } catch (error) {
            messageBarVM.showError('Save data failed.');
        } finally {
            setIsLoading(false)
        }

    }, [currentEntity, isAdd, messageBarVM, staffAndVesselCodeVM]);
    return <Sidebarheader style={{ width: '100%' }}>
        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
        <Breadcrumb>
            <HPHBreadcrumb breadcrumbData={[{ title: StaffAndVesselCodeMaintenanceConstant.SCREEN_CATEGORY },
            { title: StaffAndVesselCodeMaintenanceConstant.TITLE }]} onCurrentClick={() => { }}>
            </HPHBreadcrumb>
        </Breadcrumb>
        <StyledAction>
            {(!isAdd && (!isEditable && isRead)) &&
                <>
                    {/* Edit Button */}
                    {
                        (
                            (!isEditable && allowUpdate)
                        )
                        && <IconButton fileName='Icon-pen' size='medium' toolTipText={'Edit'} toolTipArrow={false} onClick={handleEdit} />
                    }
                    {/* Close Button */}
                    <IconButton fileName='Icon-cross' size='medium' toolTipText={'Close'} toolTipArrow={false} onClick={staffAndVesselCodeVM.onClose} />
                </>}
            {(isAdd || (isEditable && !isRead)) &&
                <>
                    {/* Reset Form Button */}
                    <IconButton fileName="Icon-reset" size="medium" toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={handleReset} />
                    {/* Save Button */}
                    {
                        (
                            (isAdd && allowCreate) ||
                            (isEditable && allowUpdate)
                        )
                        && <IconButton fileName="Icon-tick" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave()} />
                    }
                    {/* Close Button */}
                    <IconButton fileName="Icon-cross" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="left" toolTipText={'Close'} onClick={staffAndVesselCodeVM.onClose} />
                </>}
        </StyledAction>
    </Sidebarheader>
}
export default memo(StaffAndVesselCodeEditPanelTitleBar); 

import { Permission } from "presentation/constant/ANAInfo/PermissionName"; 
import { StaffAndVesselCodeMaintenanceProvider } from "presentation/store/StaffAndVesselCode/StaffAndVesselCodeMaintenanceProvider"; 
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper"; 
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper"; 
import { StaffAndVesselCodeMasterView } from "presentation/view/section/StaffAndVesselCode/StaffAndVesselCodeMasterView"; 
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled"; 
const StaffAndVesselCodeMaintenanceCont = () => { 
    return <ANAInfoWrapper permission={Permission.STAFF_VSL_CODE_MAINTENANCE}> 
        <MessageBarWrapper> 
            <StaffAndVesselCodeMaintenanceProvider> 
                <GridStyles/> 
                <StaffAndVesselCodeMasterView/> 
            </StaffAndVesselCodeMaintenanceProvider> 
        </MessageBarWrapper> 
    </ANAInfoWrapper> 
} 
export default StaffAndVesselCodeMaintenanceCont; 

export const StaffAndVesselCodeMaintenanceRequiredFieldList: string[] = [ 
    'StaffAndVesselCode',  
]; 
export const StaffAndVesselCodeMaintenanceConstant = { 
    SCREEN_CATEGORY: "Administration",     
    TITLE: "Login User & SOA Access", 
    NEW_STAFF: "New User", 
    LOGIN_USER: "Login User", 
    SOA: 'SOA', 
    BILL_TO: 'Bill To Company', 
    SERV_CODE: 'Service Code', 
    BILLING_TYPE: 'Billing Type', 
    USER_IN_CHARGE: 'User In  Charge', 
    ACTIVE_IND: 'Active Ind.', 
} 

import { EMPTY_STAFF_VSL_CODE_ENTITY, StaffAndVesselCodeEntity } from "domain/entity/StaffAndVesselCode/StaffAndVesselCodeEntity";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";
export interface StaffAndVesselCodeDropdownOptions { 
    billToCompanyDropdownOptions: DropdownProps[], 
    serviceCodeDropdownOptions: DropdownProps[], 
    loginUserDropdownOption: DropdownProps[], 
} 
export interface MasterChangeState extends BaseViewChangeSate { 
    editingEntity: StaffAndVesselCodeEntity, 
} 
export interface StaffAndVesselCodeMaintenanceModel { 
    isLoading: boolean, 
    tableData: StaffAndVesselCodeEntity[], 
    currentSelectedRow: StaffAndVesselCodeEntity, 
    selectedRows: StaffAndVesselCodeEntity[], 
    dynamicOptions: StaffAndVesselCodeDropdownOptions, 
    masterState: MasterChangeState, 
    isBackMaster: boolean, 
} 
export const EMPTY_MASTER_STAFF_VSL_CODEANY_MODEL: StaffAndVesselCodeMaintenanceModel = { 
    isLoading: false, 
    tableData: [], 
    currentSelectedRow: { ...EMPTY_STAFF_VSL_CODE_ENTITY }, 
    selectedRows: [], 
    dynamicOptions: { 
        billToCompanyDropdownOptions: [] ,
        serviceCodeDropdownOptions: [],
        loginUserDropdownOption: []
    }, 
    masterState: { 
        isSliderOpen: false, 
        isTabularDataActive: false, 
        isAdd: false, 
        isRead: false, 
        isEditable: false, 
        isSaveClicked: false, 
        lastEditRowId: "", 
        isSelected: false, 
        slider: { 
            isAdd: false, 
            isRead: false, 
            isEditable: false 
        }, 
        editingEntity: EMPTY_STAFF_VSL_CODE_ENTITY, 
    }, 
    isBackMaster: false, 
} 
